'use client';
import { Box } from '@mentimeter/ragnar-ui';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { getScreenshotUrl } from '@mentimeter/screenshots/getScreenshotUrl';
import CloneButton from '../components/CloneButton';
import { Button } from './actions';
import { DecoratedImage } from './DecoratedImage';
import { Badge, H3 } from './typography';

export interface TemplateViewT {
  presentationId: string;
  preview?: string | undefined;
  pro?: boolean;
  questionId: string;
  slug?: string;
  title: string;
}

export function Template({
  presentationId,
  preview,
  pro,
  questionId,
  slug,
  title,
}: TemplateViewT) {
  const { t } = useTranslation('common');

  return (
    <>
      <DecoratedImage
        src={
          preview ??
          getScreenshotUrl({
            presentationId,
            questionId,
            screenshotUrl: globalThis.__mentimeterEnv['NEXT_PUBLIC_SCREENSHOT_URL'] ?? '',
            width: 1600,
            height: 900,
          })
        }
        decoration="browser"
        alt={title}
      />
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        {title && <H3 mt="space2">{title}</H3>}
        {pro && <Badge>Pro</Badge>}
      </Box>
      <Box flexDirection="row" flexWrap="wrap">
        <CloneButton name={title} seriesId={presentationId} />
        {slug && (
          <Button
            ml="space1"
            variant="secondary"
            href={`/templates/p/${slug}`}
            data-testid="preview-link"
          >
            {t('common:templates.preview')}
          </Button>
        )}
      </Box>
    </>
  );
}
