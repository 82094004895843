import React, { useId, useState } from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { Tooltip } from '../Tooltip';
import { Clickable } from '../Clickable';
import { Box, type BoxT } from '../Box';
import { PopoverContainer, PopoverRoot, PopoverTrigger } from '../PopoverNew';
import type { BadgeTypes } from '../Badge';
import type { AvatarT } from './Avatar.types';
import { Avatar } from './Avatar';
import { getSummarizeDisplayNumber } from './Avatar.utils';

const AvatarNumber = ({
  displayNumber,
  popOverContent,
  size = 'default',
  ...rest
}: BoxT & {
  displayNumber: number;
  popOverContent?: React.ReactNode | undefined;
  size?: AvatarT['size'];
}) => {
  const [show, setShow] = useState(false);
  const referenceId = useId();

  const avatar = (
    <Avatar
      initials={`+${getSummarizeDisplayNumber(displayNumber)}`}
      data-testid="avatar-number"
      type="user"
      aria-label={popOverContent ? 'total avatars' : undefined}
      className="border-solid -mx-1 border-[2px] border-[theme(backgroundColor.DEFAULT)] bg-[var(--palette-gray-500)] text-[var(--palette-gray-1100)]"
      size={size}
      {...rest}
    />
  );

  if (!popOverContent) {
    return avatar;
  }

  return (
    <PopoverRoot>
      <PopoverTrigger>
        <Clickable
          flexDirection="row"
          id={referenceId}
          onClick={() => setShow(!show)}
          className="rounded-full has-hover:hover:opacity-100"
        >
          {avatar}
        </Clickable>
      </PopoverTrigger>

      <PopoverContainer>{popOverContent}</PopoverContainer>
    </PopoverRoot>
  );
};

export const AvatarButton = ({
  label,
  onClick,
  badge,
  size = 'default',
}: {
  label: string;
  onClick: () => void;
  badge?: BadgeTypes | undefined;
  size?: AvatarT['size'];
}) => {
  return (
    <Clickable onClick={onClick} className="-mx-1 flex-row">
      <Avatar
        initials={label}
        type="user"
        badge={badge}
        aria-label={label}
        className={cn([
          'border-solid',
          'border-[2px]',
          'border-[theme(backgroundColor.DEFAULT)]',
          'bg-[theme(borderColor.primary-weakest)]',
        ])}
        size={size}
      />
    </Clickable>
  );
};

const AvatarStackedCircle = ({
  itemId,
  initials,
  name,
  type = 'user',
  profilePictureUrl,
  ...rest
}: AvatarT) => {
  const referenceId = useId();

  return (
    <>
      <Avatar
        id={referenceId}
        initials={initials}
        itemId={String(itemId)}
        name={name}
        type={type}
        profilePictureUrl={profilePictureUrl}
        className={cn([
          'border-[2px]',
          'border-[theme(backgroundColor.DEFAULT)]',
          '-mx-1',
          type === 'user' && 'border-solid',
        ])}
        fillColor
        {...rest}
      />

      {name && (
        <Tooltip
          placement="bottom"
          referenceId={referenceId}
          description={name}
        />
      )}
    </>
  );
};

export interface AvatarsStackedT extends Omit<AvatarT, 'initials'> {
  avatars: AvatarT[];
  maxItems?: number;
  totalItems?: number;
  popOverContent?: React.ReactNode;
  button?: React.ReactNode;
}

export const AvatarsStacked = ({
  avatars,
  maxItems = 5,
  totalItems,
  popOverContent,
  button,
  size = 'default',
  ...rest
}: AvatarsStackedT) => {
  const displayedItems = avatars.slice(0, maxItems);
  return (
    <Box className="flex-row">
      {displayedItems.map((avatar) => (
        <AvatarStackedCircle
          key={`avatar-stacked-circle-${avatar.itemId}`}
          size={size}
          {...avatar}
          {...rest}
        />
      ))}

      {totalItems && totalItems > maxItems && (
        <AvatarNumber
          displayNumber={totalItems - displayedItems.length}
          popOverContent={popOverContent}
          size={size}
          {...rest}
        />
      )}
      {button}
    </Box>
  );
};
