import useSWR from 'swr';
import { formatPriceData } from '@mentimeter/plans-content';
import { MentiError, captureException } from '@mentimeter/errors/edge';
import { core } from '@mentimeter/http-clients';
import type { LocalPricingT } from '@mentimeter/billing';
import to from '../../../utils/to';

interface UsePricesT {
  isLoading: boolean;
  prices: LocalPricingT | undefined;
  error: Error;
}

export const usePrices = (skip = false): UsePricesT => {
  const isProd = process.env.NODE_ENV === 'production';
  const { data: prices, error } = useSWR(
    !skip ? '/prices' : null,
    async () => {
      const [fetchError, response] = await to(core().prices.get());

      if (fetchError) throw fetchError;
      if (response) {
        return formatPriceData(response['data']);
      } else {
        throw fetchError;
      }
    },
    {
      revalidateOnFocus: false,
      loadingTimeout: isProd ? 5000 : 15000,
      onLoadingSlow: () => {
        const loadingError = new MentiError('Price fetching took too long', {
          feature: 'payments',
        });
        captureException(loadingError);
        throw loadingError;
      },
    },
  );
  return {
    isLoading: !prices && !error,
    prices,
    error,
  };
};
